<template>
    <v-container align="start" justify="start">
        <h1 style="font-size: 2.5vmax">媒体新闻</h1>
        <span
            class="
                overline
                light-blue--text
                text--lighten-3
                block
                text--disabled text-uppercase
            "
            style="font-size: 1.6vmax !important"
        >
            NEWS COVERAGE
        </span>

        <v-row
            justify="start"
            no-gutters
            class="
                flex flex-row
                mt-10
                light-blue--text
                text--lighten-4 text-left
            "
            style="
                text-indent: 2em;
                width: 100%;
                overflow-x: scroll;
                flex-wrap: nowrap;
            "
        >
            <div>
                <v-img
                    height="408"
                    width="500"
                    :src="data[0].image_url"
                    content-class="d-flex align-end"
                    @click="open(data[0].link)"
                    v-ripple
                >
                    <p
                        style="
                            color: #fff;
                            background: #00000073;
                            padding: 10px;
                            width: 100%;
                        "
                        class="mb-0"
                    >
                        {{ data[0].title }}
                    </p>
                </v-img>
            </div>
            <div v-for="(item, i) of newData" :key="i" class="ml-2">
                <!-- v-for="m in 2"
                    :key="m"
                    :class="m == 2 ? 'mt-2' : ''" -->
                <v-img
                    v-for="(unit, idx) of item"
                    :key="idx"
                    :class="idx == 1 ? 'mt-2' : ''" 
                    height="200"
                    width="250"
                    content-class="d-flex align-end"
                    :src="unit.image_url"
                    @click="open(unit.link)"
                    v-ripple
                >
                    <p
                        style="
                            color: #fff;
                            background: #00000073;
                            padding: 10px;
                            width: 100%;
                        "
                        class="mb-0"
                    >
                        {{unit.title}}
                    </p>
                </v-img>
            </div>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'News',

    data: () => ({
        data: [{
            title: '',
            image_url: ''
        }]
    }),

    computed: {
        newData () {
            let data = this.data.slice(1)
            let result = []
            for(let i=0,len=data.length;i<len;i+=2){
                result.push(data.slice(i,i+2))
            }
            return result
        }
    },

    mounted () {
        this.getData()
    },

    methods: {
        getData() {
            this.axios.get('api/post?type=news').then((response)=>{
                this.data = response.data
            }).catch((response)=>{
                console.log(response)
            })
        },
        open(url) {
            window.open(url, '_blank')
        },
    }
}
</script>
