<template>
    <v-container align="start" justify="start">
        <h1 style="font-size: 2.5vmax">合作伙伴</h1>
        <span
            class="
                overline
                light-blue--text
                text--lighten-3
                block
                text--disabled text-uppercase
            "
            style="font-size: 1.6vmax !important"
        >
            PARTNERS
        </span>

        <v-row
            justify="start"
            class="flex mt-10 light-blue--text text--lighten-4 text-left"
            style="text-indent: 2em"
            v-for="m in 1"
            :key="m"
        >
            <v-col v-for="n in 5" :key="n" cols="2" align="center">
                <v-card
                    tile
                    style="background-color: rgb(55 58 124 / 20%)"
                    class="px-1"
                    v-ripple
                >
                    <v-img
                        width="70%"
                        src="http://ossweb-img.qq.com/upload/webplat/info/up/20190324/959111048109007.png"
                    ></v-img>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Partner',

    data: () => ({}),
}
</script>
