<template>
    <div v-if="show">
        <!-- Loading -->
        <div class="loading" v-if="loading">
            <v-progress-circular
                :rotate="360"
                :size="100"
                :width="10"
                :value="loadingVal"
                indeterminate
                color="white"
            >
                {{ loadingVal }}
            </v-progress-circular>
        </div>
        <!-- Loading end -->

        <v-app-bar
            flat
            color="transparent"
            dark
            fixed
            style="padding-top: 50px"
        >
            <v-toolbar-title
                class="ml-16"
                @click="toMain"
                style="position: fixed; top: 15px; left: 0"
            >
                <v-img max-width="120" src="../../assets/logo.png"></v-img
            ></v-toolbar-title>

            <v-spacer></v-spacer>

            <div style="position: fixed; top: 10px; right: 0">
                <v-btn
                    text
                    class="font-weight-black"
                    @click="toMain"
                    style="font-size: 1.1vmax"
                >
                    首页
                </v-btn>

                <v-btn
                    text
                    class="ml-10 font-weight-black"
                    style="font-size: 1.1vmax"
                    @click="$router.push('/review')"
                >
                    2020回顾
                </v-btn>
            </div>
        </v-app-bar>

        <v-card class="mx-auto menu" max-width="500" flat dark>
            <v-list flat>
                <v-list-item-group v-model="selectedItem" color="black">
                    <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        @click="$refs.fullpage.api.moveTo(i + 1, 0)"
                        color="white"
                        class="mt-3"
                    >
                        <v-list-item-icon>
                            <v-icon v-if="indexCur == i">mdi-minus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                style="color: #96a9e1; font-size: 1.2vmax"
                                :class="
                                    indexCur == i
                                        ? 'white--text item-active'
                                        : ''
                                "
                                v-text="item.name"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>

        <full-page ref="fullpage" :options="options" :skip-init="true">
            <div class="section">
                <video-bg
                    ref="video"
                    :sources="[require('../../assets/bg.mp4')]"
                    img="./assets/pc_bg2.jpg"
                >
                    <v-img
                        width="50%"
                        style="position: absolute; right: 0; top: 20%"
                        src="../../assets/bg_text.png"
                    ></v-img>
                </video-bg>
                <!-- <v-img
                    src="../../assets/bg_mask.png"
                    width="100%"
                    style="
                        width: 100%;
                        background-size: cover;
                        position: absolute;
                        top: 0;
                        z-index: 1;
                        height: 100%;
                    "
                ></v-img> -->
                <div id="mouse-icon">
                    <div id="scroll-wheel"></div>
                </div>
            </div>

            <!-- 大会议程 -->
            <div class="section full-bg">
                <agenda :stop.sync="stop"></agenda>
            </div>

            <!-- 参会指南 -->
            <div class="section full-bg">
                <guide></guide>
            </div>

            <!-- 媒体新闻 -->
            <div class="section full-bg">
                <news></news>
            </div>

            <!-- 嘉宾介绍 -->
            <div class="section full-bg">
                <master ref="master"></master>
            </div>

            <!-- 合作伙伴 -->
            <div class="section full-bg">
                <partner></partner>
            </div>

            <!-- 关于 -->
            <div class="section full-bg">
                <about></about>
            </div>
        </full-page>

        <v-dialog
            v-model="dialog"
            width="35%"
            persistent
            scrollable
            ref="dialog"
        >
            <v-card
                :style="{
                    background:
                        'url(' + require('../../assets/dialog_bg_pc.png') + ')',
                    backgroundSize: 'contain',
                    overflow: 'hidden',
                    height: dialogHeight,
                }"
            >
                <div style="padding-top: 25%"></div>
                <v-card-text
                    style="
                        overflow-y: scroll;
                        padding: 0;
                        margin: 0 auto;
                        width: 90%;
                        background: linear-gradient(#b5e7ff, #e5feff);
                    "
                >
                    <v-img :src="url"></v-img>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions style="width: 93%; margin: 0 auto">
                    <v-row>
                        <v-col cols="6" offset="3">
                            <v-btn
                                color="white"
                                style="color: #006fae"
                                @click="dialog = false"
                                block
                                tile
                                depressed
                            >
                                我已知晓
                            </v-btn></v-col
                        >
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import About from '../../components/About'
import Master from '../../components/Master'
import Partner from '../../components/Partner'
import Agenda from '../../components/Agenda'
import News from '../../components/News'
import Guide from '../../components/Guide'

export default {
    name: 'Index',

    components: {
        About,
        Partner,
        Master,
        Agenda,
        News,
        Guide,
    },

    data: () => ({
        url: '',
        stop: false,
        dialogHeight: '0px',
        dialog: true,
        loading: true,
        loadingVal: 0,
        selectedItem: '',
        indexCur: 0,
        show: false,
        items: [
            {
                p: '',
                name: '主题',
            },
            {
                p: 'agenda',
                name: '大会议程',
            },
            {
                p: 'guide',
                name: '参会指南',
            },
            {
                p: 'news',
                name: '媒体新闻',
            },
            {
                p: 'master',
                name: '嘉宾介绍',
            },
            {
                p: 'partner',
                name: '合作伙伴',
            },
            {
                p: 'about',
                name: '关于大会',
            },
        ],
        options: {
            licenseKey: 'YOUR_KEY_HEERE',
            menu: '.menu',
            anchors: [
                '',
                '?p=agenda',
                '?p=guide',
                '?p=news',
                '?p=master',
                '?p=partner',
                '?p=about',
            ],
            afterLoad: {},
            verticalCentered: false,
        },
    }),

    beforeMount() {
        if (this.$mobileCheck()) {
            window.location.href = '/#/m'
        } else {
            this.show = true
        }
    },

    mounted() {
        this.getData()

        let dialogWidth = document.querySelector(
            '#app > div.v-dialog__content.v-dialog__content--active > div > div'
        ).offsetWidth
        this.dialogHeight = `${939 / (885 / dialogWidth)}px`

        let timer = setInterval(() => {
            this.loadingVal += 10
            if (this.loadingVal == 110) {
                clearInterval(timer)
                this.loading = false
            }
        }, 100)

        this.options.afterLoad = this.upPage
        this.options.onLeave = this.leave

        // 窗口变化
        window.onresize = () => {
            return (() => {
                // let width = window.innerWidth;
                // let height = window.innerHeight;

                let dialogWidth = document
                    .querySelector(
                        '#app > div.v-dialog__content.v-dialog__content--active > div > div'
                    )
                    .getClientRects()[0].width
                document.querySelector(
                    '#app > div.v-dialog__content.v-dialog__content--active > div > div'
                ).style.height = `${939 / (885 / dialogWidth)}px`
                // console.log(width, height)
            })()
        }

        // URL锚点路径跳转
        if (this.$route.query.p) {
            let item = this.items.filter((x) => x.p == this.$route.query.p)
            if (item.length > 0) {
                this.indexCur = this.items.indexOf(item[0])
                this.$refs.fullpage.api.moveTo(this.indexCur + 1, 0)
            }
        }

        document.querySelector('html').style.overflow = 'hidden'
    },

    watch: {
        dialog(val) {
            if (val === false) {
                this.$refs.fullpage.init()
                document.querySelector('html').style.overflow = 'auto'
            }
        },
    },

    methods: {
        toMain() {
            window.location.href = '/'
        },
        upPage(origin, destination) {
            this.indexCur = destination.index
            if (this.indexCur == 0) {
                this.$refs.video.$refs.video.play()
            }

            if (this.indexCur == 4) {
                this.$refs.master.reset()
            }
        },
        leave() {
            if (this.stop) {
                return false
            }
        },
        open(url) {
            window.open(url, '_blank')
        },
        stopScroll() {
            this.$nextTick(() => {
                this.$refs.fullpage.setAllowScrolling(false)
            })
        },
        startScroll() {
            this.$refs.fullpage.setAllowScrolling(true)
        },
        getData() {
            this.axios.get('api/setting?name=remark').then((response)=>{
                this.url = response.data.value
            }).catch((response)=>{
                console.log(response)
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.menu {
    position: fixed;
    top: calc(50% - 200px);
    left: 8px;
    z-index: 200;
    background: transparent !important;

    .v-list {
        background: transparent !important;
    }
}

.item-active {
    font-size: 1.5vmax !important;
    font-weight: bolder;
}

.full-bg {
    background-image: url('../../assets/pc_bg2.jpg');
    background-size: 100% 100%;

    display: flex;
    align-items: center;
    overflow: auto;
}

.fp-tableCell {
    overflow: scroll !important;
}

.container {
    height: 65%;
    max-width: 100%;
    margin-left: 22%;
    color: #fff;
}

.v-list-item__icon {
    margin: 12px 0;
}

.loading {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    position: fixed;
    background: #000000d9;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
}

#mouse-icon {
    width: 17px;
    height: 25px;
    display: block;
    margin: -50px 0 0 -7px;
    border: 3px solid #fff;
    position: absolute;
    z-index: 700;
    opacity: 0.5;
    // top: 50%;
    bottom: 5%;
    left: 50%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

#mouse-icon::before {
    content: 'SCROLL';
    width: 60px;
    display: block;
    margin: 0 0 0 -20px;
    position: absolute;
    left: 50%;
    text-align: center;
    font: 1em 'Dosis', sans-serif;
    color: #fff;
    font-weight: 700;
    opacity: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#mouse-icon:hover {
    opacity: 1;
    cursor: pointer;
}

#mouse-icon:hover:before {
    margin-top: -30px;
    opacity: 1;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
}

#scroll-wheel {
    width: 5px;
    height: 6px;
    display: block;
    position: absolute;
    top: 0;
    left: 3px;
    background: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-animation: scrollWheel 1s linear infinite;
    -moz-animation: scrollWheel 1s linear infinite;
    -o-animation: scrollWheel 1s linear infinite;
    animation: scrollWheel 1s linear infinite;
}

@-webkit-keyframes scrollWheel {
    0% {
        top: 5px;
        opacity: 1;
    }
    100% {
        top: 20px;
        opacity: 0;
    }
}

@-moz-keyframes scrollWheel {
    0% {
        top: 5px;
        opacity: 1;
    }
    100% {
        top: 20px;
        opacity: 0;
    }
}

@-o-keyframes scrollWheel {
    0% {
        top: 5px;
        opacity: 1;
    }
    100% {
        top: 20px;
        opacity: 0;
    }
}
</style>
