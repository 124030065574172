<template>
    <v-container align="start" justify="start" style="max-width: 100%">
        <h1 style="font-size: 2.5vmax">嘉宾介绍</h1>
        <span
            class="
                overline
                light-blue--text
                text--lighten-3
                block
                text--disabled text-uppercase
            "
            style="font-size: 1.6vmax !important"
        >
            CREATIVE MASTERS TALK
        </span>

        <v-row
            justify="start"
            ref="view"
            no-gutters
            class="flex mt-10 light-blue--text text--lighten-4 text-left"
            style="overflow-x: hidden; flex-wrap: nowrap"
            :style="{ width: '100%' }"
        >
            <!-- @click="showInfo(idx)" -->
            <!-- :style="{ width: item.active ? '28%' : '17%' }" -->
            <div
                class="d-flex"
                :style="{
                    transform: curX,
                    transitionDuration:
                        curX == 'translate(0px)' ? '0ms' : '300ms',
                }"
            >
                <v-card
                    v-for="(item, idx) of items"
                    :key="idx"
                    tile
                    :style="{ width: item.active ? '20vw' : '12vw' }"
                    @click="scrollCards(idx)"
                    dark
                    v-ripple="false"
                >
                    <!-- <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                            > -->
                    <v-img
                        :src="item.image_url"
                        :lazy-src="item.image_url"
                        height="100%"
                        content-class=""
                    >
                        <div
                            v-if="item.active"
                            class="
                                d-flex
                                justify-space-between
                                flex-column
                                pa-6
                            "
                            style="height: 100%"
                        >
                            <div class="header">
                                <h3
                                    class="light-blue--text text--lighten-1 mt-n3"
                                    style="font-size: 2vmax"
                                >
                                    {{ item.name }}
                                </h3>
                                <h5
                                    class="text-uppercase"
                                    style="font-size: 1.1vmax"
                                >
                                    {{ item.e_name }}
                                </h5>
                            </div>

                            <div class="">
                                <hr style="width: 20px" class="mb-2" />
                                <span
                                    v-html="item.desc"
                                    style="font-size: 1vmax"
                                ></span>
                            </div>
                        </div>
                    </v-img>
                    <!-- <v-scale-transition> 2323 </v-scale-transition> -->
                    <!-- </v-row> -->
                </v-card>
            </div>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Master',

    data: () => ({
        initItems: [],
        initWidth: 999,
        items: [
            {
                name: '于清明',
                e_name: 'yu qingming',
                desc: '国药控股党委书记<br/>国药器械董事长',
                image_url: require('../assets/master1.jpeg'),
            },
            {
                name: '李杨',
                e_name: 'li yang',
                desc: '国药控股副总裁<br/>国药器械党委书记、总经理',
                image_url: require('../assets/master2.jpeg'),
            },
        ],
        curX: 'translate(0px)',
        viewWidth: '100%',
        itemCur: {},
    }),

    created() {
        
        // console.log(111)
        this.$nextTick(() => {
            this.getData()
            // console.log(222)
            // console.log(
            //     document.querySelector(
            //         '#app > div.v-application--wrap > main > div > div > div.fullpage-wrapper > div.section.full-bg.fp-section.fp-table.active.fp-completely > div > div > div > div:nth-child(7) > div > div.v-responsive__content'
            //     )
            // )
            // this.viewWidth = document
            //     .querySelector(
            //         '#app > div.v-application--wrap > main > div > div > div.fullpage-wrapper > div.section.full-bg.fp-section.fp-table.active.fp-completely > div > div > div > div:nth-child(7) > div > div.v-responsive__content'
            //     )
            //     .getClientRects()[0].width
            // console.log(this.viewWidth)
        })
    },

    // mounted() {
    //     this.$refs.view.addEventListener(
    //         'scroll',
    //         () => {
    //             this.fillItem()
    //         },
    //         true
    //     )
    // },

    methods: {
        getData() {
            this.axios.get('api/master').then((response)=>{
                this.items = response.data
                this.fillItem('create')
            }).catch((response)=>{
                console.log(response)
            })
        },
        fillItem(type, index) {
            if (type == 'create') {
                this.initItems = JSON.parse(JSON.stringify(this.items))
                this.$set(this.items[0], 'active', true)
            }
            let idx =
                index ?? this.items.findIndex((item) => item.active === true)
            while (this.items.length - idx < 100) {
                this.items.push(...JSON.parse(JSON.stringify(this.initItems)))
            }
            this.$forceUpdate()
        },
        scrollCards(idx) {
            for (let i of Object.keys(this.items)) {
                this.items[i].active = false
            }
            this.$set(this.items[idx], 'active', true)
            this.$forceUpdate()
            // item.active = true

            let width = document
                .querySelector(
                    '#app > div.v-application--wrap > main > div > div > div.fullpage-wrapper > div.section.full-bg.fp-section.active.fp-completely > div > div > div > div:nth-child(2)'
                )
                .getClientRects()[0].width

            if (width < this.initWidth) {
                this.initWidth = width
            }
            // let maxWidth = document
            //     .querySelector(
            //         '#app > div.v-application--wrap > main > div > div > div.fullpage-wrapper > div.section.full-bg.fp-section.fp-table.active.fp-completely > div > div > div > div:nth-child(1) > div > div.v-responsive__content'
            //     )
            //     .getClientRects()[0].width
            // this.scrollTo(width * idx)
            this.curX = `translate(-${this.initWidth * idx}px)`
            // if (idx > 1) {
            //     setTimeout(() => {
            //         this.curX = `translate(0px)`
            //     }, 50)
            // }
        },
        reset() {
            this.curX = `translate(0px)`
            for (let i of Object.keys(this.items)) {
                this.items[i].active = false
            }
            this.$set(this.items[0], 'active', true)
        },
    },
}
</script>
