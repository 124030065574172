<template>
    <v-container align="start" justify="start">
        <div v-show="!detail" :key="1">
            <h1 style="font-size: 2.5vmax">大会议程</h1>
            <span
                class="
                    overline
                    light-blue--text
                    text--lighten-3
                    block
                    text--disabled text-uppercase
                "
                style="font-size: 1.6vmax !important"
            >
                Conference agenda
            </span>

            <v-row justify="start" no-gutters class="flex mt-10">
                <h2>09:00-12:00</h2>
                &nbsp;
                <span style="line-height: 40px">am</span>
            </v-row>
            <v-row
                justify="start"
                class="flex light-blue--text text--lighten-4 text-left ma-0"
            >
                <div
                    v-for="(item, n) of items"
                    :key="n"
                    style="width: 19.2%"
                    :style="{
                        marginLeft: n == 0 ? 0 : '1%',
                    }"
                    v-ripple
                    @click="showDetail(item.id)"
                >
                    <v-img
                        src="../assets/agenda_item_1_pc.jpeg"
                        class="white--text align-start full-height"
                        content-class="d-flex justify-space-between flex-column full-height"
                    >
                        <v-card-title
                            class="justify-start mt-2"
                            style="font-size: 1.15vmax"
                            >{{ item.title }}</v-card-title
                        >
                        <v-card-text
                            class="subtitle-2"
                            style="font-size: 0.9vmax !important"
                            >{{ item.sub_title }}</v-card-text
                        >
                    </v-img>
                </div>
            </v-row>

            <v-row justify="start" no-gutters class="flex mt-10">
                <h2>13:30-16:00</h2>
                &nbsp;
                <span style="line-height: 40px">pm</span>
            </v-row>
            <v-row
                justify="start"
                class="flex light-blue--text text--lighten-4 text-left ma-0"
                v-ripple
                @click="showDetail(item.id)"
                v-if="item"
            >
                <v-img
                    height="15vh"
                    src="../assets/agenda_item_2_pc.jpeg"
                    class="white--text"
                    content-class="d-flex justify-space-between flex-column full-height pa-2"
                >
                    <h2 class="ml-5 mt-2" style="font-size: 1.5vmax !important">
                        {{ item.title }}
                    </h2>
                    <v-card-text
                        class="subtitle-2"
                        style="font-size: 0.9vmax !important"
                        >{{ item.sub_title }}</v-card-text
                    >
                </v-img>
            </v-row>
        </div>
        <div v-show="detail" :key="2">
            <h1 style="font-size: 2.5vmax" @click="detail = false
                    $emit('update:stop', false)">
                <v-icon color="white" large>mdi-arrow-left</v-icon>
                {{ detailInfo.title }}
            </h1>
            <v-row
                justify="start"
                no-gutters
                class="d-block mt-10 light-blue--text text--lighten-4 text-left"
                style="
                    text-indent: 2em;
                    line-height: 2;
                    width: 75%;
                    font-size: 1.3vmax;
                "
                v-html="detailInfo.content"
            >
            </v-row>
            <v-row
                justify="start"
                no-gutters
                class="d-flex mt-10 light-blue--text text--lighten-4 text-left"
                style="
                    text-indent: 2em;
                    line-height: 2;
                    width: 75%;
                    font-size: 1.3vmax;
                "
            >
                <v-btn outlined color="white" class="mb-5" @click="
                    detail=false
                    $emit('update:stop', false)
                ">返回</v-btn>
            </v-row>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'Agenda',

    data: () => ({
        detail: false,
        detailInfo: {},
        item: {},
        items: [],
        data: []
    }),

    mounted () {
        this.getData()
    },

    methods: {
        showDetail(id) {
            this.$emit('update:stop', true)
            this.detailInfo = this.data.filter(item => item.id == id)[0]
            this.detail = true
        },
        getData() {
            this.axios.get('api/agenda').then((response)=>{
                this.data = response.data
                this.items = response.data.filter(item => item.is_am === true)
                this.item = response.data.filter(item => item.is_am === false)[0]
            }).catch((response)=>{
                console.log(response)
            })
        }
    },
}
</script>

<style lang="scss">
.full-height {
    height: 100%;
}
.container {
    width: 70%;
    height: 70%;
}
</style>
