<template>
    <v-container align="start" justify="start">
        <h1 style="font-size: 2.5vmax">参会指南</h1>
        <span
            class="
                overline
                light-blue--text
                text--lighten-3
                block
                text--disabled text-uppercase
            "
            style="font-size: 1.6vmax !important"
        >
            Participation Guide
        </span>

        <v-row
            justify="start"
            no-gutters
            class="flex mt-10 light-blue--text text--lighten-4 text-left"
            style="text-indent: 2em"
        >
            <v-tabs
                left
                dark
                slider-color="transparent"
                hide-slider
                background-color="transparent"
            >
                <v-tab class="text-left" style="font-size: 1.2vmax !important"
                    >大会导览</v-tab
                >
                <v-tab style="font-size: 1.2vmax !important">福州特色</v-tab>

                <v-tab-item v-for="n in 2" :key="n">
                    <v-img
                        height="400"
                        src="../assets/agenda_item_2.png"
                    ></v-img>
                </v-tab-item>
            </v-tabs>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Guide',

    data: () => ({}),
}
</script>

<style lang="scss">
.v-tab {
    text-indent: 0;
}

.v-window {
    background: transparent !important;
}
</style>
