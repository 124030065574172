var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"rotate":360,"size":100,"width":10,"value":_vm.loadingVal,"indeterminate":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.loadingVal)+" ")])],1):_vm._e(),_c('v-app-bar',{staticStyle:{"padding-top":"50px"},attrs:{"flat":"","color":"transparent","dark":"","fixed":""}},[_c('v-toolbar-title',{staticClass:"ml-16",staticStyle:{"position":"fixed","top":"15px","left":"0"},on:{"click":_vm.toMain}},[_c('v-img',{attrs:{"max-width":"120","src":require("../../assets/logo.png")}})],1),_c('v-spacer'),_c('div',{staticStyle:{"position":"fixed","top":"10px","right":"0"}},[_c('v-btn',{staticClass:"font-weight-black",staticStyle:{"font-size":"1.1vmax"},attrs:{"text":""},on:{"click":_vm.toMain}},[_vm._v(" 首页 ")]),_c('v-btn',{staticClass:"ml-10 font-weight-black",staticStyle:{"font-size":"1.1vmax"},attrs:{"text":""},on:{"click":function($event){return _vm.$router.push('/review')}}},[_vm._v(" 2020回顾 ")])],1)],1),_c('v-card',{staticClass:"mx-auto menu",attrs:{"max-width":"500","flat":"","dark":""}},[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"color":"black"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,staticClass:"mt-3",attrs:{"color":"white"},on:{"click":function($event){return _vm.$refs.fullpage.api.moveTo(i + 1, 0)}}},[_c('v-list-item-icon',[(_vm.indexCur == i)?_c('v-icon',[_vm._v("mdi-minus")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.indexCur == i
                                    ? 'white--text item-active'
                                    : '',staticStyle:{"color":"#96a9e1","font-size":"1.2vmax"},domProps:{"textContent":_vm._s(item.name)}})],1)],1)}),1)],1)],1),_c('full-page',{ref:"fullpage",attrs:{"options":_vm.options,"skip-init":true}},[_c('div',{staticClass:"section"},[_c('video-bg',{ref:"video",attrs:{"sources":[require('../../assets/bg.mp4')],"img":"./assets/pc_bg2.jpg"}},[_c('v-img',{staticStyle:{"position":"absolute","right":"0","top":"20%"},attrs:{"width":"50%","src":require("../../assets/bg_text.png")}})],1),_c('div',{attrs:{"id":"mouse-icon"}},[_c('div',{attrs:{"id":"scroll-wheel"}})])],1),_c('div',{staticClass:"section full-bg"},[_c('agenda',{attrs:{"stop":_vm.stop},on:{"update:stop":function($event){_vm.stop=$event}}})],1),_c('div',{staticClass:"section full-bg"},[_c('guide')],1),_c('div',{staticClass:"section full-bg"},[_c('news')],1),_c('div',{staticClass:"section full-bg"},[_c('master',{ref:"master"})],1),_c('div',{staticClass:"section full-bg"},[_c('partner')],1),_c('div',{staticClass:"section full-bg"},[_c('about')],1)]),_c('v-dialog',{ref:"dialog",attrs:{"width":"35%","persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{style:({
                background:
                    'url(' + require('../../assets/dialog_bg_pc.png') + ')',
                backgroundSize: 'contain',
                overflow: 'hidden',
                height: _vm.dialogHeight,
            })},[_c('div',{staticStyle:{"padding-top":"25%"}}),_c('v-card-text',{staticStyle:{"overflow-y":"scroll","padding":"0","margin":"0 auto","width":"90%","background":"linear-gradient(#b5e7ff, #e5feff)"}},[_c('v-img',{attrs:{"src":_vm.url}})],1),_c('v-divider'),_c('v-card-actions',{staticStyle:{"width":"93%","margin":"0 auto"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"3"}},[_c('v-btn',{staticStyle:{"color":"#006fae"},attrs:{"color":"white","block":"","tile":"","depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 我已知晓 ")])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }